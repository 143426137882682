<template>
    <v-container fluid style="padding:2px !important; ">
        <v-row>
            <v-list-item

            >
                <v-list-item-avatar size="36">
                    <!--suppress HtmlUnknownTarget -->
                    <img :src="mention.img"
                         alt="avatar"
                         class="user default-user-size"/>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="mention.name "></v-list-item-title>
                    <v-list-item-subtitle v-text="'@'+mention.un"></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-row>

    </v-container>
</template>

<script>
    export default {
        name: "mentionable-incident",
        props: {
            mention: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
</style>