<template>
    <v-card
            class="mx-auto ma-1 mt-6"
            max-width="600"
    >
        <v-card-text>
            <div class="caption" v-if="replyToPostID">Replying to a post</div>
            <div class="caption" v-else>New Post</div>
        </v-card-text>
        <v-card-text>
            <!--            <span class="font-italic ma-0">You may use # or @</span>-->
            <v-row>
                <v-flex class="text-center" xs2>
                    <v-avatar color="orange" size="50">
                        <!--suppress HtmlUnknownTarget -->
                        <v-img :src="photoUrl"/>
                    </v-avatar>
                </v-flex>
                <v-flex class="pr-2" xs10>
                    <!--                    <span class="font-italic ma-0">You may use # or @</span>-->

                    <MentionableTextarea :classes="classes"
                                         :isLoading="isLoading"
                                         :maxlength=500
                                         :modeIdentifiers="modeIdentifiers"
                                         :rows=4
                                         @input="onInput"
                                         @onMention="onMention(instance, $event)"
                                         placeholder="Start mentioning users with @ or reach us with #help #dentalpockets #explanation etc"
                                         ref="mentionableTextarea"
                                         v-model.lazy="text"


                    />

                </v-flex>
            </v-row>
            <v-container fluid>
                <v-row class="text-right">

                    <v-flex class="mb-4" xs12>
                        <!--suppress HtmlUnknownTarget -->
                        <v-img :src="croppedImage"
                               v-if="croppedImage"
                        />

                    </v-flex>
                    <v-flex xs6>
                        <v-btn @click="imageDialog = true" color="success" outlined v-if="croppedImage">
                            <v-icon left>mdi-image-plus</v-icon>
                            Replace Image
                        </v-btn>
                        <v-btn @click="imageDialog = true" color="success" outlined v-else>
                            <v-icon left>mdi-image-plus</v-icon>
                            Add Image
                        </v-btn>
                    </v-flex>
                    <v-flex xs6>
                        <v-btn :loading="loadingButton" @click="sendPost" color="primary" dark rounded>
                            <v-icon left>mdi-send</v-icon>
                            Submit
                        </v-btn>
                    </v-flex>

                </v-row>
            </v-container>
        </v-card-text>

        <v-row justify="center">
            <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="imageDialog">

                <v-card class="fill-height">
                    <v-toolbar color="primary" dark>
                        <v-btn @click="imageDialog = false" dark icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Upload Image</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn @click="cropImage" dark text>Crop & Save</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <div class="text-center mt-5">
                        <!--suppress HtmlUnknownTag -->
                        <croppa :disabled="false"
                                :height="200"
                                :placeholder-font-size="0"
                                :prevent-white-space="true"

                                :width="350"
                                placeholder="Choose an image"
                                v-model="myCroppa"

                        />


                    </div>

                </v-card>
            </v-dialog>
        </v-row>
    </v-card>


</template>


<script>

    import {incidentMention, MODE_INCIDENT, userMention} from "./library/MentionModes";
    import debounce from 'debounce';
    import MentionableTextarea from "./library/MentionableTextarea";
    import {APIService} from "../../APIService";
    // import {dummyUsers} from "./library/dummyUsers";
    // import {backendPromise} from "./library/dummyShows";


    const apiService = new APIService();

    export default {
        created() {
            // eslint-disable-next-line no-console
            // console.log('route', this.$route.params.id);
            if (this.$route.params.id === undefined) {
                this.replyToPostID = "";
            } else {
                this.replyToPostID = this.$route.params.id;
            }

        },
        components: {
            // Popper,
            MentionableTextarea
        },

        data() {
            return {
                text: ' raja ko rani se pyar nahi tah kay ao o jo',
                items: [],
                isLoading: false,
                currentMode: 0,
                searchParam: '',
                tvSeries: [],
                replyToPostID: "",
                usernames: [],
                loadingButton: false,
                imageDialog: false,
                myCroppa: {},
                croppedImage: null,
                croppedImageBlob: null,

            }
        },

        computed: {
            classes() {
                return ['xs12', 'pa-1', 'ma-0'];
            },
            // filteredUserList() {
            //     if (this.searchParam && this.searchParam !== '') {
            //         return dummyUsers.filter(user => user.name.toLowerCase().startsWith(this.searchParam.toLowerCase()));
            //     }
            //     if (dummyUsers && dummyUsers.length > 0) {
            //         return dummyUsers;
            //     } else {
            //         return [];
            //     }
            // },
            tvList() {
                return this.tvSeries;
            },
            instance() {
                return this;
            },
            modeIdentifiers() {
                return [
                    {...userMention, suggestions: this.usernames},
                    {...incidentMention, suggestions: this.tvList}
                ]
            },
            photoUrl() {
                // console.log("PhotoURL: " + this.$store.getters.getPhotoUrl);
                return this.$store.getters.getPhotoUrl;
            },

        },
        methods: {
            cropImage() {

                this.croppedImage = this.myCroppa.generateDataUrl('image/jpeg', 0.8)

                this.imageDialog = false;
                this.myCroppa.generateBlob((blob) => {
                    this.croppedImageBlob = blob;
                }, 'image/jpeg', 0.8) // 80% compressed jpeg file

            },
            onInput(value) {
                this.$emit('input', value);
            },
            onMention: debounce((self, event) => {
                self.currentMode = event.mode;
                self.searchParam = event.searchParam;
                if (self.currentMode === MODE_INCIDENT) {
                    self.searchHashtags();
                } else {
                    self.searchUserName();
                }
            }, 350),
            searchHashtags() {
                let app = this;
                if (this.searchParam == null || this.searchParam === '' || this.searchParam.length < 2) {
                    return;
                }
                this.isLoading = true;
                apiService.getHashtags(app.searchParam).then(data => {
                    // eslint-disable-next-line no-console
                    console.log(data.data);
                    if (data.status === 201) {
                        this.tvSeries = data.data;
                        app.isLoading = false;
                    } else {
                        this.tvSeries = [];
                        app.isLoading = false;
                    }
                });
            },
            searchUserName() {
                let app = this;
                if (this.searchParam == null || this.searchParam === '' || this.searchParam.length < 2) {
                    return;
                }
                this.isLoading = true;
                apiService.getUserNames(app.searchParam).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data.data);
                    if (data.status === 201) {
                        this.usernames = data.data;
                        app.isLoading = false;
                    } else {
                        this.usernames = [];
                        app.isLoading = false;
                    }
                });
            },
            // onDummyEvent(event) {
            //     this.$refs.mentionableTextarea.clearSelection();
            //     this.$refs.mentionableTextarea.setSelection(event.text);
            // },
            sendPost() {

                // let fd = new FormData();
                // // let filename = this.image.name;
                // fd.append('file', this.croppedImageBlob, 'filename.jpg');
                // fd.append('fileNameF', this.fileName);
                // fd.append('type', this.editedItem.type);
                // fd.append('toothNumber', this.toothNumber);


                const app = this;
                if (app.text.length <= 0 && app.text.length >= 500) {
                    return;
                }
                this.loadingButton = true;
                apiService.postToPost(app.text, app.replyToPostID, app.croppedImageBlob).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data);
                    if (data.data.status === 201) {
                        // eslint-disable-next-line no-console
                        // console.log("Posted To Post Table");
                        app.text = '';
                        // noinspection JSUnresolvedVariable
                        app.$refs.mentionableTextarea.clearSelection();
                        this.loadingButton = false;

                        if (app.replyToPostID.length > 0) {
                            // eslint-disable-next-line no-console
                            // console.log("replyToPostID.length > 0")
                            app.$router.replace("/forum/post/" + app.replyToPostID)
                        } else {
                            app.$router.replace("/forum")
                        }
                    }
                });
            }
        }
    }


</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .croppa-container {
        background-color: #dcd7d7;
        border: 2px solid grey;
        border-radius: 8px;
    }

    .croppa-container:hover {
        opacity: 1;
        background-color: #8ac9ef;
    }
</style>