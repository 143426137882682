<template>
    <div v-cloak>
        <v-card
                class=" ma-2"
                v-cloak
        >
            <h1></h1>


            <div v-if="noUser">
                <v-card-text class="text-center">
                    <div>No User Found. Check the url or Go back.</div>
                </v-card-text>
            </div>
            <div v-cloak v-else>
                <v-card-text v-if="userDetails != null && Object.keys(userDetails).length > 0">
                    <v-col cols="12">

                        <!--suppress HtmlUnknownTarget -->
                        <v-img
                                :src="userDetails.avatar"
                                alt="Sunny image"
                                class="mx-auto"
                                width="150"
                        />

                        <div class="text-center">
                            <p class="display-1 font-weight-light">
                                {{userDetails.name}}
                            </p>
                            <p class="title font-weight-light">@{{requestedUserName}}</p>

                            <div v-if="!isSelf">
                                <v-btn :loading="followLoading" @click="followOrUnfollow(requestedUserName)"
                                       class="ma-2"
                                       color="indigo" outlined rounded
                                       v-if="!isFollowing">
                                    <v-icon left>mdi-plus</v-icon>

                                    Follow
                                </v-btn>
                                <v-btn :loading="followLoading" @click="followOrUnfollow(requestedUserName)"
                                       class="ma-2"
                                       color="indigo" dark rounded v-else>
                                    <v-icon left>mdi-close</v-icon>
                                    Unfollow
                                </v-btn>
                            </div>


                            <p class="subtitle-2 font-weight-light" v-if="userDetails.about != null ">
                                {{userDetails.about}}</p>
                        </div>

                    </v-col>
                    <v-row class="text-center">
                        <v-col cols="6">
                            <v-btn @click="showFollowingDialog" class="body-1 font-weight-light"
                                   style="text-transform:none !important;" text>{{followingCount}}
                                Following
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn @click="showFollowersDialog" class="body-1  font-weight-light"
                                   style="text-transform:none !important;" text>{{followersCount}}
                                Followers
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!--            <v-row>-->

                    <!--            </v-row>-->
                </v-card-text>
            </div>

        </v-card>


        <div v-if="!noUser">
            <div v-if="posts && posts.length > 0">
                <div :key="index" v-for="(post, index) in posts">
                    <ListSinglePost :is-self="isSelf" :params="post"/>
                </div>
            </div>
            <div v-if="posts!= null && posts.length === 0">
                <v-card
                        class="mx-auto mt-1 text-center"
                        max-width="600">
                    <v-card-text class="font-weight-light">No Posts Found</v-card-text>
                </v-card>
            </div>
            <div v-if="posts == null">
                <v-card
                        class="mx-auto mt-1 text-center"
                        max-width="600">
                    <v-card-text class="font-weight-light">Searching...</v-card-text>
                </v-card>
            </div>
        </div>


        <v-row justify="center">
            <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
                <v-card>
                    <v-toolbar color="indigo" dark>
                        <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-btn @click="dialog = false" dark icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-list subheader two-line>
                        <v-subheader>Total: {{followersData.length}}</v-subheader>
                        <v-list-item :key="index" v-for="(follower, index) in followersData">
                            <v-list-item-avatar size="50">
                                <!--suppress HtmlUnknownTarget -->
                                <v-img :src="follower.img"></v-img>
                            </v-list-item-avatar>


                            <v-list-item-content>
                                <v-list-item-title>{{follower.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{follower.username}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn @click="viewProfile(follower.username)" class="ma-2" color="indigo" icon
                                >
                                    <v-icon left>mdi-eye</v-icon>

                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                    </v-list>

                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import ListSinglePost from "./ListSinglePost";
    import {APIService} from "../../APIService";
    import localForage from "localforage";


    const apiService = new APIService();

    export default {
        name: "UserPosts",
        components: {ListSinglePost},
        created() {
            this.requestedUserName = this.$route.params.username;

            window.addEventListener('scroll', () => {
                this.bottom = this.bottomVisible()
            });
        },
        watch: {
            bottom(bottom) {
                if (bottom) {
                    this.loadMore()
                }
            },
            '$route.params.username': {
                handler(newValue) {

                    if (newValue && newValue !== this.requestedUserName) {
                        if (newValue.length > 0) {
                            // eslint-disable-next-line no-console
                            // console.log("Calling form watch");
                            this.requestedUserName = newValue;

                            this.getPostsFromUser();
                        }

                    }

                },
                immediate: true,
            }
        },
        mounted() {
            this.$nextTick(function () {
                if (this.requestedUserName && this.requestedUserName.length > 0) {
                    // eslint-disable-next-line no-console
                    // console.log("Calling form MOunted");

                    // this.getPostsFromUser();
                }
            });
        },
        data: () => ({
            noUser: false,
            dialog: false,
            dialogTitle: "",
            requestedUserName: "",
            params: {},
            showMenu: false,

            rules: [v => v.length <= 500 || 'Max 500 characters'],
            postText: '',
            menuItems: [
                {icon: "mdi-flag-outline", title: 'Report'},
                {icon: "mdi-account-plus-outline", title: 'Follow'},
            ],
            posts: null,
            userDetails: null,
            requestInProgress: false,
            followLoading: false,
            isFollowing: "",
            followingCount: 0,
            followersCount: 0,
            isSelf: null,
            followersData: [],
            prevPageNo: 2,
            bottom: false,

        }),
        methods: {
            followOrUnfollow(userName) {
                if (userName && userName.length === 0) {
                    return;
                }
                this.$store.commit("setShowProgress", true);
                this.followLoading = true;
                apiService.followOrUnfollowUser(userName).then(data => {
                    if (data.data.status === 201) {
                        // eslint-disable-next-line no-console
                        // console.log(data.data.reason);


                        if (data.data.reason === 110) {
                            this.isFollowing = true;
                            this.followersCount = parseInt(this.followersCount) + 1;
                        } else {
                            this.isFollowing = false;
                            this.followersCount = parseInt(this.followersCount) - 1;

                        }

                        // eslint-disable-next-line no-console
                        // console.log(this.isFollowing);


                    }
                    this.$store.commit("setShowProgress", false);
                    this.followLoading = false;
                });

            },
            getPostsFromUser() {

                if (this.requestInProgress) {
                    return;
                }
                // eslint-disable-next-line no-console
                // console.log("processing");
                this.requestInProgress = true;
                let app = this;
                app.$store.commit("setShowProgress", true);

                localForage.getItem('user-post-' + this.requestedUserName, function (err, value) {
                    // eslint-disable-next-line no-console
                    // console.log("getvalue", value);
                    app.params = value;
                    app.$store.commit("setShowProgress", false);

                });

                localForage.getItem('user-details-' + this.requestedUserName, function (err, value) {
                    // eslint-disable-next-line no-console
                    // console.log("getvalue", value);
                    app.userDetails = value;

                    app.isFollowing = value.isFollowing;
                    app.followersCount = value.followersCount;
                    app.followingCount = value.followingCount;
                    app.isSelf = value.isSelf;

                    app.$store.commit("setShowProgress", false);
                });

                if (this.requestedUserName && this.requestedUserName.length === 0) {
                    return;
                }

                this.$store.commit("setShowProgress", true);


                apiService.getUserDetailsFromUserName(this.requestedUserName).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log("userDetails", data);


                    if (data.status === 404) {
                        this.noUser = true;
                    }
                    if (data.status !== 200) {
                        this.userDetails = [];
                    } else {
                        // eslint-disable-next-line no-console
                        // console.log("userDetails", data);

                        this.userDetails = data.info;
                        this.isFollowing = data.info.isFollowing = data.isFollowing;
                        this.followersCount = data.info.followersCount = data.followersCount;
                        this.followingCount = data.info.followingCount = data.followingCount;
                        this.isSelf = data.info.isSelf = data.isSelf;

                        // eslint-disable-next-line no-unused-vars
                        localForage.setItem('user-details-' + this.requestedUserName, data.info).then(function (value) {
                            // Do other things once the value has been saved.
                            // eslint-disable-next-line no-console
                            // console.log("values value set", value);


                        }).catch(function (err) {
                            // This code runs if there were any errors
                            // eslint-disable-next-line no-console
                            console.log(err);
                        });

                    }


                    // eslint-disable-next-line no-console
                    // console.log(this.replies);
                    this.$store.commit("setShowProgress", false);

                });


                apiService.getUserPosts(this.requestedUserName).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log("posts", data.data.data);

                    if (data.data.status === 202) {
                        this.posts = [];
                    } else {
                        this.posts = data.data.data;

                        // eslint-disable-next-line no-unused-vars
                        localForage.setItem('user-post-' + this.requestedUserName, data.data.data).then(function (value) {
                            // Do other things once the value has been saved.
                            // eslint-disable-next-line no-console
                            // console.log("values value set", value);


                        }).catch(function (err) {
                            // This code runs if there were any errors
                            // eslint-disable-next-line no-console
                            console.log(err);
                        });

                    }


                    // eslint-disable-next-line no-console
                    // console.log(this.replies);
                    this.$store.commit("setShowProgress", false);

                });
                this.requestInProgress = false;

            },
            showFollowingDialog() {
                this.$store.commit("setShowProgress", true);

                apiService.getFollowingList(this.requestedUserName).then(data => {
                    if (data.data.status === 202) {
                        this.posts = [];
                        return;
                    } else {
                        this.followersData = data.data.data;
                        this.dialogTitle = this.userDetails.name + " Following"
                        this.dialog = true;
                    }
                    // eslint-disable-next-line no-console
                    // console.log(this.replies);
                    this.$store.commit("setShowProgress", false);
                });


            },
            showFollowersDialog() {
                this.$store.commit("setShowProgress", true);

                apiService.getFollowersList(this.requestedUserName).then(data => {
                    if (data.data.status === 202) {
                        this.posts = [];
                        return;
                    } else {
                        this.followersData = data.data.data;
                        this.dialogTitle = this.userDetails.name + " Followers"
                        this.dialog = true;
                    }
                    // eslint-disable-next-line no-console
                    // console.log(this.replies);
                    this.$store.commit("setShowProgress", false);
                });

                this.dialogTitle = this.userDetails.name + " Followers"
                this.dialog = true;
            },
            viewProfile(username) {
                username = username.replace("@", "");
                this.$router.push({name: 'forumUser', params: {username: username}});
                this.dialog = false;
            },
            loadMore() {
                const app = this;
                apiService.getUserPosts(this.requestedUserName, app.prevPageNo).then(data => {

                    if (data.data.status === 200) {
                        app.prevPageNo = app.prevPageNo + 1;

                        data.data.data.forEach(element => {
                            this.posts.push(element);
                        });
                        // eslint-disable-next-line no-unused-vars
                        localForage.setItem('user-post-' + this.requestedUserName, this.posts).then(function (value) {
                            // Do other things once the value has been saved.
                            // eslint-disable-next-line no-console
                            // console.log("values value set", value);


                        }).catch(function (err) {
                            // This code runs if there were any errors
                            // eslint-disable-next-line no-console
                            console.log(err);
                        });
                    } else {
                        // eslint-disable-next-line no-console
                        console.log("FinalLength", this.posts.length);
                    }
                });
            },
            bottomVisible() {
                const scrollY = window.scrollY;
                const visible = document.documentElement.clientHeight;
                const pageHeight = document.documentElement.scrollHeight;
                const bottomOfPage = visible + scrollY >= pageHeight;
                return bottomOfPage || pageHeight < visible
            },
        }
    }
</script>

<style scoped>

</style>
