<template>
    <div>
        <!--    <h1>Forum</h1>-->
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "Forum"
    }
</script>

<style scoped>

</style>