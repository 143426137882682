<template>

    <v-flex xs12>
        <div :key="index" v-for="(params, index) in allPosts">
            <ListSinglePost :params="params"/>
        </div>


        <!--        <v-card-->
        <!--                class="mx-auto mt-1"-->
        <!--                color="#26c6da"-->
        <!--                dark-->
        <!--                max-width="600"-->
        <!--        >-->
        <!--            <v-card-title>-->
        <!--                <v-icon-->
        <!--                        large-->
        <!--                        left-->
        <!--                >-->
        <!--                    mdi-twitter-->
        <!--                </v-icon>-->
        <!--                <span class="title font-weight-light">Twitter</span>-->
        <!--            </v-card-title>-->

        <!--            <v-card-text class="headline font-weight-bold">-->
        <!--                "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type-->
        <!--                invalid as well."-->
        <!--            </v-card-text>-->

        <!--            <v-card-actions>-->
        <!--                <v-list-item class="grow">-->
        <!--                    <v-list-item-avatar color="grey darken-3">-->
        <!--                        <v-img-->
        <!--                                class="elevation-6"-->
        <!--                                src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"-->
        <!--                        ></v-img>-->
        <!--                    </v-list-item-avatar>-->

        <!--                    <v-list-item-content>-->
        <!--                        <v-list-item-title>Evan You</v-list-item-title>-->
        <!--                    </v-list-item-content>-->

        <!--                    <v-row-->
        <!--                            align="center"-->
        <!--                            justify="end"-->
        <!--                    >-->
        <!--                        <v-icon class="mr-1">mdi-heart</v-icon>-->
        <!--                        <span class="subheading mr-2">256</span>-->
        <!--                        <span class="mr-1">·</span>-->
        <!--                        <v-icon class="mr-1">mdi-share-variant</v-icon>-->
        <!--                        <span class="subheading">45</span>-->
        <!--                    </v-row>-->
        <!--                </v-list-item>-->
        <!--            </v-card-actions>-->
        <!--        </v-card>-->


        <v-btn
                @click="showInsert"
                bottom
                class="mb-12"
                color="pink"
                dark
                fab
                fixed
                right
                v-if="!isMobile"
        >
            <v-icon>mdi-pen</v-icon>
        </v-btn>
        <v-btn
                @click="showInsert"
                bottom
                color="pink"
                dark
                fab
                fixed
                right
                v-else

        >
            <v-icon>mdi-pen</v-icon>
        </v-btn>
    </v-flex>

</template>

<script>

    import {APIService} from "../../APIService";
    import ListSinglePost from "./ListSinglePost";
    import localforage from "localforage";

    const apiService = new APIService();

    export default {
        name: "Forum",
        components: {ListSinglePost},
        data: () => ({
            isMobile: window.innerWidth < 600,

            showMenu: false,
            rules: [v => v.length <= 500 || 'Max 500 characters'],
            postText: '',
            postTextRules: [
                v => !!v || 'Post text is required',
                v => (v && v.length <= 500) || 'Post must be less than 500 characters',
            ],

            params: {
                id: "",
                img: "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light",
                name: "Evan You",
                username: "@evanyou",
                time: "20h",
                body: "Typescript is awesome. You should try it sometime. lets see if time permits we shall give it a try for sure.",
                likes: "300",
                replies: "14",
            },
            allPosts: [],
            text: '',

            loading: false,
            items: [],
            prevPageNo: 2,
            bottom: false,


        }),
        mounted() {
            this.$nextTick(function () {

                // eslint-disable-next-line no-console
                // console.log("Mounted MeritListDialog");
                let app = this;
                localforage.getItem('posts-home', function (err, value) {
                    // Run this code once the value has been
                    // loaded from the offline store.
                    // eslint-disable-next-line no-console
                    // console.log(value);

                    app.allPosts = value;
                });

                this.showPosts();
            });

        },
        created() {
            window.addEventListener('scroll', () => {
                this.bottom = this.bottomVisible()
            });
            // this.loadMore();
        },
        watch: {
            bottom(bottom) {
                if (bottom) {
                    this.loadMore()
                }
            }
        },
        methods: {
            showInsert() {
                this.$router.push({name: "insertPost"})
            },

            showPosts() {
                // eslint-disable-next-line no-console
                console.log("Loading original.......");


                this.$store.commit("setShowProgress", true);
                apiService.showAllPosts().then(data => {

                    this.allPosts = data.data.data;
                    // eslint-disable-next-line no-console
                    // console.log("Got", data.data.data);


                    this.$store.commit("setForumUserName", data.un);

                    localStorage.setItem("selfUsername", data.un);
                    // eslint-disable-next-line no-unused-vars
                    localforage.setItem('selfUsername', data.un, function (err) {
                        // if err is non-null, we got an error
                        // eslint-disable-next-line no-console
                        // console.log("Error", err);
                    });

                    // eslint-disable-next-line no-console
                    // console.log(this.allPosts);
                    // eslint-disable-next-line no-unused-vars
                    localforage.setItem('posts-home', data.data.data, function (err) {
                        // if err is non-null, we got an error
                        // eslint-disable-next-line no-console
                        // console.log("Error", err);
                    });


                    this.$store.commit("setShowProgress", false);

                });

            },
            showPost() {
                this.$router.push("/forum/post");
            },
            sendPost() {
                const app = this;

                if (app.postText.length <= 0 && app.postText.length >= 500) {
                    return;
                }


                apiService.postToPost(app.postText).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data.data.status);
                    if (data.data.status === 201) {
                        // eslint-disable-next-line no-console
                        console.log("Posted To Post Table");
                        app.postText = '';
                    }
                });
            },
            loadMore() {
                const app = this;
                apiService.showAllPosts(app.prevPageNo).then(data => {


                    if (data.data.status === 200) {
                        app.prevPageNo = app.prevPageNo + 1;

                        data.data.data.forEach(element => {
                            this.allPosts.push(element);
                        });
                        // eslint-disable-next-line no-unused-vars
                        localforage.setItem('posts-home', this.allPosts, function (err) {
                        });

                    } else {
                        // eslint-disable-next-line no-console
                        console.log("FinalLength", this.allPosts.length);
                    }
                });
            },
            bottomVisible() {
                const scrollY = window.scrollY;
                const visible = document.documentElement.clientHeight;
                const pageHeight = document.documentElement.scrollHeight;
                const bottomOfPage = visible + scrollY >= pageHeight;
                return bottomOfPage || pageHeight < visible
            },
        }
    }
</script>

<style scoped>

</style>