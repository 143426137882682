<template>
    <div>
        <PostMain :params="params"/>

        <div v-if="replies && replies.length > 0">
            <div :key="index" v-for="(reply, index) in replies">
                <ListSinglePost :params="reply"/>
            </div>
        </div>
        <div v-if="replies!= null && replies.length === 0">
            <v-card
                    class="mx-auto mt-1 text-center"
                    max-width="600">
                <v-card-text class="font-weight-light">No Replies Found</v-card-text>
            </v-card>
        </div>
        <div v-if="replies == null">
            <v-card
                    class="mx-auto mt-1 text-center"
                    max-width="600">
                <v-card-text class="font-weight-light">Searching...</v-card-text>
            </v-card>
        </div>

        <v-btn
                @click="showInsert"
                bottom
                class="mb-12"
                color="pink"
                dark
                fab
                fixed
                right
                v-if="!isMobile"
        >
            <v-icon>mdi-pen</v-icon>
        </v-btn>
        <v-btn
                @click="showInsert"
                bottom
                color="pink"
                dark
                fab
                fixed
                right
                v-else

        >
            <v-icon>mdi-pen</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import ListSinglePost from "./ListSinglePost";
    import PostMain from "./PostMain";
    import {APIService} from "../../APIService";
    import * as localforage from "localforage";
    // import Replies from "./Replies";
    const apiService = new APIService();

    export default {
        name: "Post",
        components: {ListSinglePost, PostMain},
        // components: { PostMain},

        created() {
            // eslint-disable-next-line no-console
            // console.log('route', this.$route.params.id);
            this.postID = this.$route.params.id;
            // this.params = this.$store.getters.getForumPost;
            this.getPostBody();
            window.addEventListener('scroll', () => {
                this.bottom = this.bottomVisible()
            });
        },
        watch: {
            bottom(bottom) {
                if (bottom) {
                    this.loadMore()
                }
            },
            '$route.params.id': {
                handler(newValue) {
                    // eslint-disable-next-line no-console
                    // console.log("ROute is", newValue);
                    // const {postId} = newValue

                    if (newValue && newValue !== this.postID) {
                        this.postID = newValue;
                        this.getPostBody();
                        this.getRepliesWithPostID(newValue);
                    }

                },
                immediate: true,
            }
        },

        mounted() {
            this.$nextTick(function () {

                // eslint-disable-next-line no-console
                // console.log("Mounted MeritListDialog");

                this.getRepliesWithPostID(this.postID);

            });
        },

        data: () => ({
            isMobile: window.innerWidth < 600,

            postID: "",
            params: {},
            showMenu: false,

            rules: [v => v.length <= 500 || 'Max 500 characters'],
            postText: '',
            replies: null,
            prevPageNo: 2,
            bottom: false,
        }),
        methods: {
            showInsert() {
                this.$router.push({name: "insertPost", params: {id: this.postID}})
            },
            getPostBody() {
                let id = this.postID;
                let app = this;

                this.$store.commit("setShowProgress", true);
                localforage.getItem('post' + id, function (err, value) {
                    // Run this code once the value has been
                    // loaded from the offline store.
                    // eslint-disable-next-line no-console
                    // console.log(value);

                    app.params = value;
                    app.$store.commit("setShowProgress", false);

                });

                this.$store.commit("setShowProgress", true);
                localforage.getItem('posts-reply-' + id, function (err, value) {
                    // Run this code once the value has been
                    // loaded from the offline store.
                    // eslint-disable-next-line no-console
                    // console.log(value);

                    app.replies = value;
                    app.$store.commit("setShowProgress", false);

                });

                // app.$store.commit("setShowProgress", false);

            },
            getRepliesWithPostID(postID) {
                this.$store.commit("setShowProgress", false);

                apiService.showAllReplies(postID).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log("replies", data.data.status);

                    if (data.data.status === 202) {
                        this.replies = [];
                    } else {
                        this.params.replies = data.data.data.length;
                        this.replies = data.data.data;
                        localforage.setItem('posts-reply-' + postID, data.data.data);
                    }


                    this.$store.commit("setShowProgress", false);

                    // eslint-disable-next-line no-console
                    // console.log(this.replies);

                });
            },
            showPost() {
                this.$router.push("/forum/post");
            },
            loadMore() {
                const app = this;
                let id = this.postID;

                apiService.showAllReplies(id, app.prevPageNo).then(data => {

                    if (data.data.status === 200) {
                        app.prevPageNo = app.prevPageNo + 1;

                        data.data.data.forEach(element => {
                            this.replies.push(element);
                        });
                        // eslint-disable-next-line no-unused-vars
                        localforage.setItem('posts-reply-' + id, this.replies, function (err) {
                        });
                    } else {
                        // eslint-disable-next-line no-console
                        console.log("FinalLength", this.replies.length);
                    }
                });
            },
            bottomVisible() {
                const scrollY = window.scrollY;
                const visible = document.documentElement.clientHeight;
                const pageHeight = document.documentElement.scrollHeight;
                const bottomOfPage = visible + scrollY >= pageHeight;
                return bottomOfPage || pageHeight < visible
            },
        }
    }
</script>

