<template>
    <div>
        <h1>#{{requestedHashtag}}</h1>
        <!--        <PostMain :menu-items="menuItems" :params="params"/>-->
        <!--        <Replies :menu-items="menuItems"/>-->

        <div v-if="replies && replies.length > 0">
            <div :key="index" v-for="(reply, index) in replies">
                <ListSinglePost :params="reply"/>
            </div>
        </div>
        <div v-if="replies!= null && replies.length === 0">
            <v-card
                    class="mx-auto mt-1 text-center"
                    max-width="600">
                <v-card-text class="font-weight-light">No Hashtags Found</v-card-text>
            </v-card>
        </div>
        <div v-if="replies == null">
            <v-card
                    class="mx-auto mt-1 text-center"
                    max-width="600">
                <v-card-text class="font-weight-light">Searching...</v-card-text>
            </v-card>
        </div>

    </div>
</template>

<script>
    import ListSinglePost from "./ListSinglePost";
    // import PostMain from "./PostMain";
    import {APIService} from "../../APIService";
    import * as localforage from "localforage";
    // import Replies from "./Replies";
    const apiService = new APIService();

    export default {
        name: "Hashtag",
        components: {ListSinglePost},
        // components: { PostMain},

        created() {

            this.requestedHashtag = this.$route.params.hashtag;
            this.getPostBody();

            window.addEventListener('scroll', () => {
                this.bottom = this.bottomVisible()
            });
        },
        watch: {
            bottom(bottom) {
                if (bottom) {
                    this.loadMore()
                }
            },
            '$route.params.hashtag': {
                handler(newValue) {
                    if (newValue && newValue !== this.requestedHashtag) {
                        if (newValue.length > 0) {
                            this.requestedHashtag = newValue;
                            this.getPostBody();
                            this.getRequestedHashtagPosts(newValue);
                        }

                    }

                },
                immediate: true,
            }
        },

        mounted() {
            this.$nextTick(function () {
                if (this.requestedHashtag && this.requestedHashtag.length > 0) {
                    this.getRequestedHashtagPosts(this.requestedHashtag);
                }
            });
        },

        data: () => ({
            requestedHashtag: "",
            params: {},
            showMenu: false,

            rules: [v => v.length <= 500 || 'Max 500 characters'],
            postText: '',
            menuItems: [
                {icon: "mdi-flag-outline", title: 'Report'},
                {icon: "mdi-account-plus-outline", title: 'Follow'},
            ],
            replies: null,
            prevPageNo: 2,
            bottom: false,
        }),
        methods: {

            getPostBody() {

                let app = this;
                app.$store.commit("setShowProgress", true);

                localforage.getItem('hashtag-' + this.requestedHashtag, function (err, value) {
                    // Run this code once the value has been
                    // loaded from the offline store.
                    // eslint-disable-next-line no-console
                    // console.log(value);

                    app.params = value;
                    app.$store.commit("setShowProgress", false);

                });
            },
            getRequestedHashtagPosts(requestedHashtag) {

                if (this.requestedHashtag && this.requestedHashtag.length === 0) {
                    return;
                }
                this.$store.commit("setShowProgress", true);

                apiService.showHashtags(requestedHashtag).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log("replies", data.data.status);
                    if (data.data.status === 202) {
                        this.replies = [];
                    } else {
                        this.replies = data.data.data;
                        localforage.setItem('hashtag-' + requestedHashtag, data.data.data);
                    }


                    // eslint-disable-next-line no-console
                    // console.log(this.replies);
                    this.$store.commit("setShowProgress", false);

                });
            },
            showPost() {
                this.$router.push("/forum/post");
            },
            loadMore() {
                this.$store.commit("setShowProgress", true);

                const app = this;
                apiService.showHashtags(this.requestedHashtag, app.prevPageNo).then(data => {

                    if (data.data.status === 200) {
                        app.prevPageNo = app.prevPageNo + 1;

                        data.data.data.forEach(element => {
                            this.replies.push(element);
                        });
                        // eslint-disable-next-line no-unused-vars
                        localforage.setItem('hashtag-' + this.requestedHashtag, this.replies, function (err) {
                        });
                    } else {
                        // eslint-disable-next-line no-console
                        console.log("FinalLength", this.replies.length);
                    }
                    app.$store.commit("setShowProgress", false);

                });
            },
            bottomVisible() {
                const scrollY = window.scrollY;
                const visible = document.documentElement.clientHeight;
                const pageHeight = document.documentElement.scrollHeight;
                const bottomOfPage = visible + scrollY >= pageHeight;
                return bottomOfPage || pageHeight < visible
            },
        }
    }
</script>

