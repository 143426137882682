<template>
    <v-card
            class="mx-auto mt-1 pa-1"
            max-width="600">

        <v-list-item two-line>
            <v-list-item-avatar color="grey darken-3" size="75">
                <!--suppress HtmlUnknownTarget -->
                <v-img
                        :src="params.img"
                        class="elevation-6"
                ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>{{params.name}}</v-list-item-title>
                <v-list-item-subtitle class="font-italic">{{params.username}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="menuClickAction(111, params)" class="red--text" v-if="!isSelf()">
                            <v-list-item-avatar>
                                <v-icon color="red">mdi-flag</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title class="red--text">Report This Post</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                                :key="index"
                                @click="menuClickAction(index, params)"
                                v-for="(item, index) in menuItems">
                            <v-list-item-avatar>
                                <v-icon :color="item.color"> {{item.icon}}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title :class="item.color+'--text'">{{ item.title }} {{params.username}}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="menuClickAction(999, params)" class="red--text" v-if="isSelf()">
                            <v-list-item-avatar>
                                <v-icon color="red">mdi-trash-can-outline</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title class="red--text">Delete This Post</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item-action>


        </v-list-item>

        <!--suppress HtmlUnknownTarget -->
        <v-img :src="params.images" v-if="params.images"/>
        <v-card-subtitle class=" pt-0 pb-2 caption font-weight-light">{{params.created}}</v-card-subtitle>

        <v-card-text @click="handleClicks" class="headline font-weight-light handle-this" v-html="params.body"
                     v-linkified/>


        <hr class="greyhr">
        <v-card-text>
            <strong class="black--text">{{params.likes}}</strong> Likes · <strong
                class="black--text">{{params.replies}}</strong> Replies
        </v-card-text>
        <hr class="greyhr">
        <v-card-actions>
            <v-list-item class="grow">
                <v-row
                        align="center"
                        class="grow text-center"
                >
                    <v-flex xs5>
                        <v-btn @click.stop="likePost(params)"
                               @mousedown.stop=""
                               @touchstart.stop=""
                               class="ma-2 grey--text text--darken-1"
                               text
                        >
                            <v-icon color="red" left v-if="params.isLiked">mdi-heart</v-icon>
                            <v-icon left v-else>mdi-heart</v-icon>

                        </v-btn>

                    </v-flex>
                    <v-flex xs2>
                        <span class="mr-1">·</span>

                    </v-flex>
                    <v-flex xs5>
                        <v-btn @click.stop="replyToPost"
                               @mousedown.stop=""
                               @touchstart.stop=""
                               class="ma-2 grey--text text--darken-1"
                               text
                        >
                            <v-icon left>mdi-reply</v-icon>
                        </v-btn>
                    </v-flex>


                </v-row>
            </v-list-item>
        </v-card-actions>
        <v-row justify="center">
            <v-dialog max-width="350" persistent v-model="reportUserDialog">
                <v-card>
                    <v-card-title class="headline">Report {{reportUserDialogTest}}?</v-card-title>
                    <v-card-text>Please send us an email by clicking the link given below
                        and justify the reasons to report the user. Please do not change the subject line of the email.
                        We shall launch an investigation on <strong> Urgent Basis </strong> and will seek legal help if
                        needed. <p class="text-center"><a
                                :href="'mailto:dentalguru2016@gmail.com?subject=URGENT!! Report Web App User ' + reportUserDialogTest"
                                target="_blank">dentalguru2016@gmail.com</a>
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="reportUserDialog = false" color="green darken-1" text>OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-snackbar
                :color="snackbarColor"
                :timeout=3000
                v-model="snackbar"
        >
            {{ snackbarText }}
            <v-btn
                    @click="snackbar = false"
                    text
            >
                Close
            </v-btn>
        </v-snackbar>
    </v-card>
</template>
<script>
    import {APIService} from "../../APIService";

    const apiService = new APIService();

    export default {

        name: 'PostMain',
        props: {
            params: {
                id: "",
                img: "",
                name: "",
                username: "",
                time: "",
                body: "",
                likes: "",
                replies: "",
            },
            // menuItems: {},


        },
        data: () => ({
            postID: "",
            othersMenuItems: [
                {icon: "mdi-account-plus-outline", title: 'Follow'},
                {icon: "mdi-flag-outline", title: 'Report'},
            ],
            selfMenuItems: [],
            reportUserDialog: false,
            reportUserDialogTest: "",
            snackbar: false,
            snackbarColor: "",
            snackbarText: ""
        }),
        computed: {
            profileUserName() {
                if (this.$store.getters.getProfileUserName) {
                    return this.$store.getters.getProfileUserName
                } else {
                    return localStorage.getItem("selfUsername");
                }

            },
            menuItems() {
                if (this.isSelf()) {
                    return this.selfMenuItems;
                } else {
                    // eslint-disable-next-line no-console
                    // console.log(this.othersMenuItems);
                    return this.othersMenuItems;
                }
            },
        },
        created() {
            // eslint-disable-next-line no-console
            // console.log('route', this.$route.params.id);
            this.postID = this.$route.params.id;
        },
        methods: {
            menuClickAction(val, post) {
                // eslint-disable-next-line no-console
                // console.log(val);
                //    999 means delete this post. 0 means Follow user, 1 means report user
                if (val === 999) {

                    apiService.deletePost(post.id).then(data => {
                        // eslint-disable-next-line no-console
                        // console.log(data);
                        if (data.data.status === 201) {
                            post.body = "Post Deleted";
                            this.snackbarColor = "success";
                            this.snackbarText = "Post Deleted";
                            this.snackbar = true;
                        } else {
                            this.snackbarColor = "error";
                            this.snackbarText = "Unable to delete post. Contact admin if the problem persists";
                            this.snackbar = true;
                        }
                    });
                }

                if (val === 111) {
                    apiService.reportPostID(post.id).then(data => {
                        // eslint-disable-next-line no-console
                        // console.log(data);
                        if (data.data.status === 201) {
                            post.body = "Content Under Moderation";
                            this.snackbarColor = "success";
                            this.snackbarText = "Post Reported";
                            this.snackbar = true;
                        } else {
                            this.snackbarColor = "error";
                            this.snackbarText = "Unable to report post. Contact admin if the problem persists";
                            this.snackbar = true;
                        }
                    });
                }


                if (val === 0) { // Follow user
                    // eslint-disable-next-line no-console
                    // console.log(post);
                    let username = post.username.replace("@", "");
                    this.$router.push({name: 'forumUser', params: {username: username}});
                    this.showMenu = false;
                }

                if (val === 1) { // Report user
                    this.reportUserDialogTest = post.username;
                    this.reportUserDialog = true;
                    this.showMenu = false;

                }
            },
            isSelf() {
                return this.params.username === this.profileUserName;
            },
            handleClicks(event) {
                // ensure we use the link, in case the click has been received by a sub-element
                let {target} = event
                while (target && target.tagName !== 'A') target = target.parentNode
                // handle only links that occur inside the component and do not reference external resources
                if (target && target.matches(".handle-this a:not([href*='://'])") && target.href) {
                    // some sanity checks taken from vue-router:
                    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                    const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event
                    // don't handle with control keys
                    if (metaKey || altKey || ctrlKey || shiftKey) return
                    // don't handle when preventDefault called
                    if (defaultPrevented) return
                    // don't handle right clicks
                    if (button !== undefined && button !== 0) return
                    // don't handle if `target="_blank"`
                    if (target && target.getAttribute) {
                        const linkTarget = target.getAttribute('target')
                        if (/\b_blank\b/i.test(linkTarget)) return
                    }
                    // don't handle same page links/anchors
                    const url = new URL(target.href)
                    const to = url.pathname
                    if (window.location.pathname !== to && event.preventDefault) {
                        event.preventDefault()
                        this.$router.push(to)
                    }
                }
            },
            likePost(params) {

                let postId = params.id;

                // eslint-disable-next-line no-console
                // console.log(postId);
                apiService.markPostFavUnfav(postId).then(data => {

                    // eslint-disable-next-line no-console
                    // console.log("markedFav", data);

                    if (data.data.status === 200) {
                        params.isLiked = false;
                        params.likes = parseInt(params.likes) - 1;
                    }
                    if (data.data.status === 201) {
                        params.isLiked = true;
                        params.likes = parseInt(params.likes) + 1;

                    }


                });

            },


            replyToPost() {

                this.$router.push({name: "insertPost", params: {id: this.postID}})

            }
        }
    }
</script>
<style scoped>
    .greyhr {
        border: 1px solid rgb(230, 236, 240) !important;
    }
</style>