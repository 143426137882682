<template>
    <v-card
            class="mx-auto mt-1"
            max-width="600"
            v-on:click="showPost(params, $event)"
    >
        <v-list-item
                @click.stop="showUserProfile(params)"
                @mousedown.stop=""
                @touchstart.stop=""
                two-line>

            <v-list-item-avatar color="grey darken-3" size="50">
                <!--suppress HtmlUnknownTarget -->
                <v-img
                        :src="params.img"
                        class="elevation-6"
                ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>{{params.name}} · <span class="ml-1 font-weight-light">{{params.username}}</span>
                </v-list-item-title>
                <!--                <v-list-item-subtitle class="font-italic">{{params.username}}</v-list-item-subtitle>-->
            </v-list-item-content>
            <v-list-item-action v-if="menuItems.length > 0">
                <v-btn
                        @click.stop="showMenu = !showMenu"
                        @mousedown.stop=""
                        @touchstart.stop=""

                        class="grey--text lighten-2"
                        icon
                >
                    <v-icon v-if="!showMenu">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>


                </v-btn>
            </v-list-item-action>


        </v-list-item>

        <!--suppress HtmlUnknownTarget -->
        <v-img :src="params.images" v-if="params.images"/>
        <v-card-subtitle class="text-right font-weight-light mr-5 pa-0">{{params.time}}</v-card-subtitle>

        <v-card-text @click="handleClicks" class="title font-weight-light pt-2 handle-this" v-html="params.body"
                     v-linkified/>


        <hr class="greyhr">

        <v-card-actions class="ma-0 pa-0">
            <v-list-item class="grow">
                <v-row
                        align="center"
                        class="grow text-center"
                >
                    <v-col cols="5">
                        <v-btn @click.stop="likePost(params)"
                               @mousedown.stop=""
                               @touchstart.stop=""
                               class="ma-2 grey--text text--darken-1"
                               text
                        >
                            <v-icon color="red" left v-if="params.isLiked">mdi-heart</v-icon>
                            <v-icon left v-else>mdi-heart</v-icon>
                            <span class="subheading">{{params.likes}}</span>


                        </v-btn>

                    </v-col>
                    <v-col cols="1">
                        <span class="mr-1">·</span>

                    </v-col>
                    <v-col cols="5">
                        <v-btn @click.stop="showPost(params, $event)"
                               @mousedown.stop=""
                               @touchstart.stop=""
                               class="ma-2 grey--text text--darken-1"
                               text
                        >
                            <v-icon left>mdi-reply</v-icon>
                            <span class="subheading">{{params.replies}}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-card-actions>

        <v-bottom-sheet inset v-model="showMenu">

            <v-sheet>
                <v-toolbar dense>
                    <v-toolbar-title>More</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="showMenu = !showMenu" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-list>
                    <v-list-item
                            @click="menuClickAction(111, params)"
                            v-if="!isSelf()"
                    >
                        <v-list-item-avatar>
                            <v-icon color="red"> mdi-flag</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="red--text">Report This Post</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                            :key="index"
                            @click="menuClickAction(index, params)"
                            v-for="(item, index) in menuItems"
                    >
                        <v-list-item-avatar>
                            <v-icon> {{item.icon}}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title>{{ item.title }} {{params.username}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-sheet>
        </v-bottom-sheet>
        <v-row justify="center">
            <v-dialog max-width="350" persistent v-model="reportUserDialog">
                <v-card>
                    <v-card-title class="headline">Report {{reportUserDialogTest}}?</v-card-title>
                    <v-card-text>Please send us an email by clicking this link <a
                            :href="'mailto:dentalguru2016@gmail.com?subject=URGENT!! Report' + reportUserDialogTest">dentalguru2016@gmail.com</a>
                        and justify the reasons to report the user. Please do not change the subject line of the email.
                        We shall launch an investigation on <strong> Urgent Basis </strong> and will seek legal help if
                        needed.
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="reportUserDialog = false" color="green darken-1" text>OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-snackbar
                :color="snackbarColor"
                :timeout=3000
                v-model="snackbar"
        >
            {{ snackbarText }}
            <v-btn
                    @click="snackbar = false"
                    text
            >
                Close
            </v-btn>
        </v-snackbar>
    </v-card>
</template>
<script>

    import * as localforage from "localforage";

    import {APIService} from "../../APIService";

    const apiService = new APIService();

    export default {
        name: 'ListSinglePost',
        props: {

            params: {
                id: "",
                img: "",
                name: "",
                username: "",
                time: "",
                body: "",
                likes: "",
                replies: "",
                isLiked: false,
            }
        },
        data: () => ({
            showMenu: false,
            othersMenuItems: [
                {icon: "mdi-account-plus-outline", title: 'Follow'},
                {icon: "mdi-flag-outline", title: 'Report'},
            ],
            selfMenuItems: [
                // {icon: "mdi-flag-outline", title: 'Report'},
            ],
            reportUserDialog: false,
            reportUserDialogTest: "",
            snackbar: false,
            snackbarColor: "",
            snackbarText: ""

        }),
        computed: {
            profileUserName() {
                if (this.$store.getters.getProfileUserName) {
                    return this.$store.getters.getProfileUserName
                } else {
                    return localStorage.getItem("selfUsername");
                }

            },
            menuItems() {
                if (this.isSelf()) {
                    return this.selfMenuItems;
                } else {
                    return this.othersMenuItems;
                }
            },

        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            menuClickAction(val, post) {
                // eslint-disable-next-line no-console
                console.log(val);
                //    111 means report this post. 0 means Follow user, 1 means report user
                if (val === 111) {
                    apiService.reportPostID(post.id).then(data => {
                        // eslint-disable-next-line no-console

                        if (data.data.status === 201) {
                            post.body = "Content Under Moderation";
                            this.snackbarColor = "success";
                            this.snackbarText = "Post Reported";
                            this.snackbar = true;

                            this.showMenu = false;

                        } else {
                            this.snackbarColor = "error";
                            this.snackbarText = "Unable to report post. Contact admin if the problem persists";
                            this.snackbar = true;

                            this.showMenu = false;

                        }
                    });


                }


                if (val === 0) { // Follow user
                    // eslint-disable-next-line no-console
                    // console.log(post);
                    this.showUserProfile(post);
                }

                if (val === 1) { // Report user
                    this.reportUserDialogTest = post.username;
                    this.reportUserDialog = true;
                    this.showMenu = false;

                }
            },

            showUserProfile(post) {
                let username = post.username.replace("@", "");
                this.$router.push({name: 'forumUser', params: {username: username}});
                this.showMenu = false;
            },

            // profileUserName() {
            //     localforage.getItem('selfUsername').then(function (value) {
            //         return value;
            //     }).catch(function (err) {
            //         // This code runs if there were any errors
            //         // eslint-disable-next-line no-console
            //         console.log(err);
            //     });
            //     // return this.$store.getters.getProfileUserName;
            // },
            isSelf() {
                return this.params.username === this.profileUserName;
            },
            handleClicks(event) {
                // eslint-disable-next-line no-console
                let currentRoute = window.location.href;
                // ensure we use the link, in case the click has been received by a sub-element
                let {target} = event
                let targetRoute = target.href;

                if (currentRoute === targetRoute) {
                    // eslint-disable-next-line no-unused-vars
                    this.$router.push(this.$route.path).catch(() => {
                    })
                    event.preventDefault();
                    return false;
                }

                // while (target && target.tagName !== 'A') target = target.parentNode
                // handle only links that occur inside the component and do not reference external resources
                if (currentRoute !== targetRoute && target && target.matches(".handle-this a:not([href*='://'])") && target.href) {


                    // some sanity checks taken from vue-router:
                    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                    const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event
                    // don't handle with control keys
                    if (metaKey || altKey || ctrlKey || shiftKey) return
                    // don't handle when preventDefault called
                    if (defaultPrevented) return
                    // don't handle right clicks
                    if (button !== undefined && button !== 0) return
                    // don't handle if `target="_blank"`
                    if (target && target.getAttribute) {
                        const linkTarget = target.getAttribute('target')
                        if (/\b_blank\b/i.test(linkTarget)) {
                            event.preventDefault()
                            return;
                        }
                    }
                    // don't handle same page links/anchors
                    const url = new URL(target.href)
                    const to = url.pathname
                    if (window.location.pathname !== to && event.preventDefault) {
                        event.preventDefault()
                        this.$router.push(to)
                    } else {
                        event.preventDefault()
                    }
                } else {
                    event.preventDefault();
                }
            },
            likePost(params) {

                let postId = params.id;

                // eslint-disable-next-line no-console
                // console.log(postId);
                apiService.markPostFavUnfav(postId).then(data => {

                    // eslint-disable-next-line no-console
                    // console.log("markedFav", data);

                    if (data.data.status === 200) {
                        params.isLiked = false;
                        params.likes = parseInt(params.likes) - 1;
                    }
                    if (data.data.status === 201) {
                        params.isLiked = true;
                        params.likes = parseInt(params.likes) + 1;

                    }


                });

            },
            showPost(params, e) {
                if (e.target.classList.contains("hashtag") || e.target.classList.contains("mention")) {
                    e.stopPropagation()
                    // console.log("Cancelled Navigation!")
                } else {
                    this.$store.commit("setForumPost", params);
                    let id = params.id;

                    let app = this;
                    localforage.setItem('post' + id, params).then(function () {
                        // Do other things once the value has been saved.
                        // eslint-disable-next-line no-console
                        // console.log(value);

                        app.$router.push("/forum/post/" + id);
                    }).catch(function (err) {
                        // This code runs if there were any errors
                        // eslint-disable-next-line no-console
                        console.log(err);
                    });

                }


            },

        }
    }
</script>
<style scoped>
    .greyhr {
        border: 1px solid rgb(230, 236, 240) !important;
    }
</style>
