<template>
    <v-container fluid>
        <v-row>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>#{{mention.hashtag}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-row>
        <!--        <v-flex class="pa-2" xs12>-->
        <!--           <span>-->
        <!--&lt;!&ndash;               <strong>{{mention.serial}} </strong> - {{mention.subject}}&ndash;&gt;-->
        <!--               <strong></strong>-->
        <!--           </span>-->
        <!--        </v-flex>-->
    </v-container>
</template>

<script>
    export default {
        name: "mentionable-incident",
        props: {
            mention: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
</style>